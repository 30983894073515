import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Organization, OrganizationList, OrganizationMetrics } from '@core-shared/models/organization.model';
import { Site, SiteConfigurationUpdate, SiteList } from '@core-shared/models/site.model';
import { PanelList } from '@core-shared/models/panel.model';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { ActivePower, Connectivity, ConnectorStatus } from '@core-shared/models/metrics.model'

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  baseUrl = `${environment.apiEndpoint}/organizations`
  constructor(private http: HttpClient) { }

  get organizationName(): string | null {
    return sessionStorage.getItem('organizationName');
  }

  setOrganizationName(name: string) {
    sessionStorage.setItem('organizationName', name);
  }

  getOrganization(organizationId: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.baseUrl}/${organizationId}`);
  }

  getOrganizationActivePower(organizationId: string): Observable<ActivePower> {
    return this.http.get<ActivePower>(`${this.baseUrl}/${organizationId}/active-power`)
  }

  getOrganizationConnectivity(organizationId: string): Observable<Connectivity> {
    return this.http.get<Connectivity>(`${this.baseUrl}/${organizationId}/connectivity`)
  }

  getOrganizationConnectorStatus(organizationId: string): Observable<ConnectorStatus> {
    return this.http.get<ConnectorStatus>(`${this.baseUrl}/${organizationId}/connector-statuses`)
  }

  getHistoricalOrganizationConnectorStatus(organizationId: string, data: TelemetryQuery): Observable<ConnectorStatus> {
    return this.http.post<ConnectorStatus>(`${this.baseUrl}/${organizationId}/connector-statuses`, data);
  }

  getOrganizations(): Observable<OrganizationList> {
    return this.http.get<OrganizationList>(this.baseUrl);
  }

  getOrganizationMetrics(organizationId: string): Observable<OrganizationMetrics> {
    return this.http.get<OrganizationMetrics>(`${this.baseUrl}/${organizationId}/metrics`);
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.baseUrl}/${organization.organizationId}`, organization);
  }

  getSites(organizationId: string): Observable<SiteList> {
    return this.http.get<SiteList>(`${this.baseUrl}/${organizationId}/sites`);
  }

  getUserOrganizations(userId: string): Observable<OrganizationList> {
    return this.http.get<OrganizationList>(`${this.baseUrl}/users/${userId}`);
  }

  updateSite(organizationId: string, siteId: string, site: SiteConfigurationUpdate): Observable<Site> {
    return this.http.put<Site>(`${this.baseUrl}/${organizationId}/sites/${siteId}`, site);
  }

  addPanelToGroup(data: any): Observable<null> {
    return this.http.post<null>(`${this.baseUrl}/${data.organizationId}/sites/${data.siteId}/panels/${data.panelId}/groups/${data.groupId}`, data);
  }

  createOrganization(data: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${this.baseUrl}`, data);
  }

  unassignedPanels(): Observable<PanelList> {
    return this.http.get<PanelList>(`${environment.apiEndpoint}/panels/unassigned`);
  }

  createSite(site: Site, orgId: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/${orgId}/sites`, site)
  }

  getOrganizationStations(orgId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/${orgId}/stations`)
  }
}
