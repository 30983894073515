import { ServerErrorComponent } from './server-error/server-error.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StateService } from '@core-shared/services/state/state.service';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AuthService } from '@global/services/auth/auth.service';
import { LoginFailedComponent } from './login-failed/login-failed.component';
import { LogoutComponent } from './logout/logout.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: 'verify-email',
    component: VerifyEmailComponent
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
   path: 'logout', 
   component: LogoutComponent
  },
  {
    path: '500',
    component: ServerErrorComponent,
  },
  {
    path: 'core',
    canActivate: [MsalGuard],
    canActivateChild: [StateService],
    canMatch: [() => inject(AuthService).canMatchIsAuthenticated()],
    loadChildren: () => import('./core-module/core.module').then(c => c.CoreModule),
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    paramsInheritanceStrategy: 'always',
    // useHash: true,
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
