<div id="top-nav" class="top-nav">
    <img src="assets/images/logo.svg" alt="Epurpl logo">
    <p class="site-manager"></p>
</div>
<div class="login-main">
    <p-card>
        <ng-container *ngIf="showForm">
            <p-card header="Verify your email address and set your password">
                <p>
                    Password Requirements: Minimum 8 characters and maximum 64 characters in length 3 of 4 character
                    classes
                    - uppercase, lowercase, number, symbol.
                </p>
            </p-card>
            <app-form-container *ngIf="userLoginForm" [formGroup]="userLoginForm" submitLabel="Submit"
                [submitFunc]="onSubmit" [buttonPosition]="'center'" [hasReset]="false">
                <form [formGroup]="userLoginForm">
                    <app-form-input label="Email Address" [control]="userLoginForm.controls['email']">
                        <input type="text" formControlName="email" />
                    </app-form-input>
                    <ng-container formGroupName="passwords">
                        <app-form-input label="Password" [control]="userLoginForm.controls['passwords'].get('password')">
                            <input [type]="showPassword? 'text': 'password'" formControlName="password" />
                            <span [class]="showPassword? 'eye pi pi-eye-slash': 'eye pi pi-eye'"
                                (click)="showPassword = !showPassword"></span>
                        </app-form-input>
                        <app-form-input label="Confirm Password" [control]="userLoginForm.controls['passwords'].get('confirmPassword')">
                            <input [type]="showPassword? 'text': 'password'" formControlName="confirmPassword" />
                            <span [class]="showPassword? 'eye pi pi-eye-slash': 'eye pi pi-eye'"
                                (click)="showPassword = !showPassword"></span>
                        </app-form-input>
                    </ng-container>
                    <p *ngIf="userLoginForm.controls.passwords.errors?.notSame" style="color: red;">Password does not match Confirm Password</p>
                </form>
               
            </app-form-container>
        </ng-container>

        <ng-container *ngIf="isSending">
            <div class="card" style="display: flex; flex-direction: column; align-items: center;">
                <p>Verifying</p>
                <p-progressSpinner></p-progressSpinner>
            </div>
        </ng-container>

        <ng-container *ngIf="isRedirecting">
            <div class="card" style="display: flex; flex-direction: column; align-items: center;">
                <p>Verification successful</p>
                <p>Redirecting to login page in {{countdown}} seconds</p>
                <i class="pi pi-check-circle" style="color: green; font-size: 5rem; margin-top: 10px;"></i>
            </div>
        </ng-container>

    </p-card>
</div>



<app-footer></app-footer>