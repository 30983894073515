import { FormControl } from "@angular/forms";

export interface Connector {
    connectorId: string;
    stationId: string;
    switchId: string;
    name: string;
    connectorIndex: number;
    phaseA: boolean;
    phaseB: boolean;
    phaseC: boolean;
    prioritized: boolean;
    enabled: boolean;
    commissioned: boolean;
    powerLimit: number;
}

export interface Connectors {
    limit: number;
    returnedResults: number;
    totalResults: number;
    offset: number;
    connectors: Connector[];
}

export enum Status {
    Available = 'Available',
    Faulted = 'Faulted',
    Charging = 'Charging',
    Preparing = 'Preparing',
    Finishing = 'Finishing',
    Charged = 'Charged',
    Uncommissioned = 'Uncommissioned',
    Disabled = 'Disabled',
    SuspendedEV = 'SuspendedEV',
    SuspendedEVSE = 'SuspendedEVSE',
    Unknown = 'Unknown',
    Unavailable = 'Unavailable'
  };

export interface ConnectorConfigurationUpdateForm {
    name: FormControl<string | null>;
    connectorIndex: FormControl<number | null>;
    prioritized: FormControl<boolean | null>;
    enabled: FormControl<boolean | null>;
    commissioned: FormControl<boolean | null>;
    powerLimit: FormControl<number | null>;
}

export interface ConnectorConfigurationUpdate {
    name: string | null;
    connectorIndex: number | null;
    prioritized: boolean | null;
    enabled: boolean | null;
    commissioned: boolean | null;
    powerLimit: number | null;
}
