// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://dev-api-01.atompower.com/ev/v1',
  requireHttps: false,
  showDebugInformation: true,
  protocol: 'http',
  websocketProtocol: 'ws',
  apiKey: '',
  mapKey: 'AIzaSyDIjN6pZ_W99CbTnyycq3U2r_YHu-6RYvw',
  msalConfig: {
    auth: {
      clientId: 'f992bc23-ae16-4512-9a3d-054ae5e933c4',
      redirectUri: '/',
      postLogoutRedirectUri: '/logout',
      navigateToLoginRequestUrl: false
    },
    debug: {
      loggerCallbackEnabled: false,
      piiLoggingEnabled: false
    }
  },
  apiConfig: {
    scopes: ["https://atompowerdev.onmicrosoft.com/de76ec9c-eb58-420b-ba0a-5af49cb9af59/SiteManager.User"],
    uri: "https://dev-api-01.atompower.com/ev/v1/",
    unprotectedResources: ["https://dev-api-01.atompower.com/ev/v1/verify-email"]
  },
  b2cPolicies: {
    names: {
      signIn: "B2C_1_signin",
    },
    authorities: {
      signIn: {
        authority: "https://atompowerdev.b2clogin.com/atompowerdev.onmicrosoft.com/b2c_1_signin"
      },
    },
    authorityDomain: "atompowerdev.b2clogin.com",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
