import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { GroupsService } from '@core-shared/services/groups/groups.service';
import { PanelsService } from '@core-shared/services/panels/panels.service';
import { SitesService } from '@core-shared/services/sites/sites.service';
import { map, Observable } from 'rxjs';
import { HttpRequestState, httpRequestStates } from 'ngx-http-request-state';
import { ConnectorStatuses } from '@core-shared/models/metrics.model';

@Component({
  selector: 'app-fault-indicator',
  templateUrl: './fault-indicator.component.html',
  styleUrls: ['./fault-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaultIndicatorComponent implements OnChanges {
  @Input() type!: string;
  @Input() organizationId!: string;
  @Input() siteId!: string;
  @Input() groupId!: string;
  @Input() panelId!: string;

  hasFaults$!: Observable<HttpRequestState<boolean>>;
  faultMessage: string = 'Faulted';
  constructor(private siteService: SitesService,
    private groupService: GroupsService,
    private panelService: PanelsService) { }

  ngOnChanges(): void {
    switch (this.type) {
      case 'Site':
        if (this.siteId) {
          this.hasFaults$ = this.checkForFaults(this.siteService.getSiteConnectorStatuses(this.organizationId, this.siteId))
        }
        break;
      case 'Group':
        if (this.groupId && this.siteId) {
          this.hasFaults$ = this.checkForFaults(this.groupService.getGroupConnectorStatuses(this.organizationId, this.groupId, this.siteId))
        }
        break;
      case 'Panel':
        if (this.panelId && this.siteId) {
          this.hasFaults$ = this.checkForFaults(this.panelService.getPanelConnectorStatuses(this.organizationId, this.panelId, this.siteId))
        }
        break;
      default:
        break;
    }
  }

  checkForFaults(connectorStatuses: Observable<ConnectorStatuses>): Observable<HttpRequestState<boolean>> {
    return connectorStatuses
      .pipe(
        map(connectorStatuses => {
          let hasFaults = false;
          connectorStatuses.connectorStatuses.forEach(status => {
            if (status.activeFault) {
              this.faultMessage = status.faultDescription
              hasFaults = true
            }
          })
          return hasFaults
        })
      ).pipe(httpRequestStates())
  }

}
