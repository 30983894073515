import { Station, StationConfigurationUpdate, StationMetrics } from './../../models/station.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, map } from 'rxjs';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { ConnectorStatuses } from '@core-shared/models/metrics.model';
import { ActivePower } from '@core-shared/models/metrics.model';
import { changeConnectorStatuses } from '@core-shared/components/connector-status-modifier/connector-status-modifier';
@Injectable({
  providedIn: 'root'
})
export class StationService {
  baseUrl = `${environment.apiEndpoint}/organizations`
  constructor(private http: HttpClient) { }

  get stationName(): string | null {
    return sessionStorage.getItem('stationName');
  }

  setStationName(name: string) {
    sessionStorage.setItem('stationName', name);
  }

  getStation(organizationId: string, siteId: string, stationId: string): Observable<Station> {
    return this.http.get<Station>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}`)
  }

  getHistoricalStationTelemetry(organizationId: string, siteId: string, stationId: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/telemetry`, data)
  }

  getHistoricalStationPowerTelemetry(organizationId: string, siteId: string, stationId: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/power-telemetry`, data)
  }

  getHistoricalStationConnectorStatus(organizationId: string, siteId: string, stationId: string, data: TelemetryQuery): Observable<ConnectorStatuses> {
    return this.http.post<ConnectorStatuses>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/connector-status`, data)
  }

  getStationMetrics(organizationId: string, siteId: string, stationId: string, data: TelemetryQuery): Observable<StationMetrics> {
    return this.http.post<StationMetrics>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/metrics`, data)
  }

  deleteStation(organizationId: string, siteId: string, stationId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}`)
  }

  updateStation(organizationId: string, siteId: string, stationId: string, station: StationConfigurationUpdate): Observable<Station> {
    return this.http.put<Station>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}`, station);
  }

  createStation(organizationId: string, siteId: string, station: Station): Observable<Station> {
    return this.http.post<Station>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations`, station);
  }

  getStationActivePower(organizationId: string, siteId: string, stationId: string): Observable<ActivePower> {
    return this.http.get<ActivePower>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/active-power`)
  }

  getStationConnectorStatuses(organizationId: string, siteId: string, stationId: string): Observable<ConnectorStatuses> {
    return this.http.get<ConnectorStatuses>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/connector-statuses`)
  }

  getStationConnectorStatusesWithStatusOverride(organizationId: string, stationId: string, siteId: string): Observable<ConnectorStatuses> {
    return this.http.get<ConnectorStatuses>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/connector-statuses`)
      .pipe(
        map((x) => changeConnectorStatuses(x))
      );
  }
}
