<div id="top-nav" class="top-nav">
    <img src="assets/images/logo.svg" alt="Epurpl logo">
    <p class="site-manager"></p>
</div>

<section>
    <ng-container *ngIf="(showLoadingIndicator | async); else notLoading">
        <p-progressSpinner></p-progressSpinner>
    </ng-container>
    <ng-template #notLoading>
        <div
            style="display:flex;flex-direction: column; justify-content: center;align-items:center; height: 600px; width: 600px;">
            <h1>Click to login</h1>
            <p-button (click)="login()" label="Login"></p-button>
        </div>
    </ng-template>
</section>
<app-footer></app-footer>