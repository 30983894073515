<section>
    <div
        style="display:flex;flex-direction: column; justify-content: center;align-items:center; height: 600px; width: 600px;">
        <i class="pi pi-info-circle" style="font-size: 3rem"></i>
        <h1>Something went wrong</h1>
        <p>Return to login?</p>
        <div style="display: flex; justify-content: center;">
            <button pButton routerLink="/home" label="Home" (click)="clearCache()"></button>
        </div>
    </div>
</section>
