import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { interval, take } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {

  constructor(private router: Router) { }

  ngOnInit(): void {
     interval(1000).pipe(take(1)).subscribe({
      next: () => { 
        this.router.navigate(['/']);
      }
    })
  }
}
