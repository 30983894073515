import { PanelList } from '@core-shared/models/panel.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { GroupList } from '@core-shared/models/group.model';
import { Site, SiteList } from '@core-shared/models/site.model';
import { Connector } from '@core-shared/models/connector.model'
import { Connectivity, ActivePower, ConnectorStatuses, Metrics, HistoricalSitePowerTelemetries } from '@core-shared/models/metrics.model'
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { Station } from '@core-shared/models/station.model';


@Injectable({
  providedIn: 'root'
})

export class SitesService {
  baseUrl = `${environment.apiEndpoint}/organizations`

  constructor(private http: HttpClient) { }

  get siteName(): string | null {
    return sessionStorage.getItem('siteName');
  }

  setSiteName(name: string) {
    sessionStorage.setItem('siteName', name);
  }

  getSites(organizationId: string): Observable<SiteList> {
    return this.http.get<SiteList>(`${this.baseUrl}/${organizationId}/sites`)
  }

  getSite(organizationId: string, siteId: string): Observable<Site> {
    return this.http.get<Site>(`${this.baseUrl}/${organizationId}/sites/${siteId}`)
  }

  getUserOrganizationSites(organizationId: string): Observable<SiteList> {
    return this.http.get<SiteList>(`${this.baseUrl}/${organizationId}/user-sites`);
  }

  getSiteMetrics(organizationId: string, siteId: string, data: TelemetryQuery): Observable<Metrics> {
    return this.http.post<Metrics>(`${this.baseUrl}/${organizationId}/sites/${siteId}/metrics`, data)
  }

  getSiteConnectors(organizationId: string, siteId: string): Observable<Connector[]> {
    return this.http.get<Connector[]>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors`)
  }

  getSiteConnectorStatuses(organizationId: string, siteId: string): Observable<ConnectorStatuses> {
    return this.http.get<ConnectorStatuses>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connector-statuses`)
  }

  getSiteConnectivity(organizationId: string, siteId: string): Observable<Connectivity> {
    return this.http.get<Connectivity>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectivity`)
  }


  getHistoricalSiteConnectorStatus(organizationId: string, siteId: string, data: TelemetryQuery): Observable<ConnectorStatuses> {
    return this.http.post<ConnectorStatuses>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connector-status`, data)
  }

  getHistoricalSitePowerTelemetry(organizationId: string, siteId: string, data: TelemetryQuery): Observable<HistoricalSitePowerTelemetries> {
    return this.http.post<HistoricalSitePowerTelemetries>(`${this.baseUrl}/${organizationId}/sites/${siteId}/power-telemetry`, data)
  }

  updateSite(organizationId: string, site: Site): Observable<Site> {
    return this.http.put<Site>(`${this.baseUrl}/${organizationId}/sites/`, site);
  }

  getGroups(organizationId: string, siteId: string): Observable<GroupList> {
    return this.http.get<GroupList>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups`)
  }

  getPanels(organizationId: string, siteId: string): Observable<PanelList> {
    return this.http.get<PanelList>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels`)
  }

  getHistoricalSiteConnectivity(organizationId: string, siteId: string, data: TelemetryQuery) {
    return this.http.post<Connectivity>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectivity`, data);
  }

  getSiteActivePower(organizationId: string, siteId: string): Observable<ActivePower> {
    return this.http.get<ActivePower>(`${this.baseUrl}/${organizationId}/sites/${siteId}/active-power`)
  }

  getSiteTimezone(organizationId: string, siteId: string): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/${organizationId}/sites/${siteId}/time-zone`)
  }
  /**
   *
   * @returns a list of all postgresql time zones
   */
  getSiteTimezones(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiEndpoint}/options/time-zones`)
  }

  getSiteStations(organizationId: string, siteId: string): Observable<Station> {
    return this.http.get<Station>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations`)
  }
}
