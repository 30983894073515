import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, UrlTree } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthToken } from './models/token-response.model';
import { Observable, Subject, catchError, filter, lastValueFrom, map, of, startWith, switchMap, takeUntil, tap, timer } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserVerifyEmailRequest } from './models/verify-email.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly ACCESS_TOKEN_NAME: string = "accessToken";

  readonly USER_PERMISSION_REFRESH_INTERVAL: number = 300_000;
  private refreshUserPermissionTimer$ = new Subject();
  private stopRefreshTimer$ = new Subject();

  constructor(private router: Router,
    private http: HttpClient,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService) {
    // Detect when user has logged in
    // TODO: Handle failures
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None && this.msalService.instance.getAllAccounts().length > 0),
        switchMap(() => this.initializeRefreshUserPermissionTimer()),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  get user_id(): string {
    const userId = this.getAuthToken()?.user.id;
    return userId != null ? userId : '';
  }

  get access_token(): string {
    const accessToken = sessionStorage.getItem('accessToken');
    return accessToken != null ? accessToken : '';
  }

  get access_token_expiration(): number | null {
    const decodedToken = this.getAuthToken();
    return decodedToken?.claims.exp ?? null;
  }

  get eula() {
    const decodedToken = this.getAuthToken();
    return decodedToken?.user.eula ?? false;
  }

  get orgMap(): Map<string, number[]> {
    const decodedToken = this.getAuthToken();
    return decodedToken?.permissions.organization ?? new Map<string, number[]>();
  }

  get siteMap(): Map<string, number[]> {
    const decodedToken = this.getAuthToken();
    return decodedToken?.permissions.site ?? new Map<string, number[]>();
  }

  get orgAndSiteList() {
    const decodedToken = this.getAuthToken();

    if (decodedToken == null) {
      return {
        sites: [],
        orgs: [],
        firstOrg: null,
        firstSite: null
      }
    }

    const orgList = Object.keys(decodedToken.permissions.organization);
    const siteList = Object.keys(decodedToken.permissions.site);

    return {
      sites: siteList,
      orgs: orgList,
      firstOrg: orgList.shift() ?? null,
      firstSite: siteList.shift() ?? null
    }
  }

  get isAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  get superUser(): boolean {
    const decodedToken = this.getAuthToken();
    return decodedToken?.user.superUser ? decodedToken.user.superUser : false;
  }


  initializeRefreshUserPermissionTimer(): Observable<AuthToken> {
    return this.refreshUserPermissionTimer$.pipe(
      startWith(void 0),
      switchMap(() => timer(this.USER_PERMISSION_REFRESH_INTERVAL, this.USER_PERMISSION_REFRESH_INTERVAL)),
      switchMap(() => this.getPermissions()),
      tap((userInfo) => this.setAuthToken(userInfo)),
      takeUntil(this.stopRefreshTimer$),
    );
  }

  // Parse the accesstoken in the session storage to get the user permissions
  getAuthToken(): AuthToken | null {
    const token = sessionStorage.getItem(this.ACCESS_TOKEN_NAME);
    if (token) {
      return JSON.parse(token) as AuthToken;
    }
    return null;
  }

  setAuthToken(authToken: AuthToken): void {
    return sessionStorage.setItem(this.ACCESS_TOKEN_NAME, JSON.stringify(authToken));
  }

  initialLoginNavigation(): void {
    const orgAndSiteList = this.orgAndSiteList;

    if (!this.eula) {
      this.navToEula();
    } else if (this.superUser) {
      this.navToOrganizationList();
    } else if (orgAndSiteList.orgs.length > 1) {
      this.navToOrganizationList();
    } else if (orgAndSiteList.sites.length > 1 && orgAndSiteList.firstOrg) {
      this.navToSiteList(orgAndSiteList.firstOrg)
    } else if (orgAndSiteList.orgs.length === 1 && orgAndSiteList.sites.length === 1 && orgAndSiteList.firstOrg && orgAndSiteList.firstSite) {
      this.navToSiteDashboard(orgAndSiteList.firstOrg, orgAndSiteList.firstSite);
    } else {
      this.navToOrganizationList();
    }
  }

  logout(): void {
    this.stopRefreshTimer$.next(0);
    this.stopRefreshTimer$.complete();
    this.msalService.logoutRedirect();
    sessionStorage.clear();
  }

  loginFailed(): void {
    this.msalService.logoutRedirect();
    localStorage.clear();
    sessionStorage.clear();
  }

  async navToDashboard(orgId: string, siteId: string): Promise<void> {
    await this.router.navigate(['core/organization/' + orgId + '/sites/' + siteId + '/dashboard']);
  }

  async navToEula(): Promise<void> {
    await this.router.navigate(['core/eula']);
  }

  async navToOrganizationList(): Promise<void> {
    await this.router.navigate(['core/organization']);
  }

  async navToSiteList(orgId: string): Promise<void> {
    await this.router.navigate(['core/organization/' + orgId + '/sites']);
  }

  async navToSiteDashboard(orgId: string, siteId: string): Promise<void> {
    await this.router.navigate(['core/organization/' + orgId + '/sites/' + siteId + '/dashboard']);
  }

  private setUserId() {
    const decodedToken = this.getAuthToken();
    sessionStorage.setItem('user_id', decodedToken?.user.id ?? '');
  }

  canMatchIsAuthenticated() {
    if (!this.isAuthenticated) {
      return this.msalService.loginRedirect();
    }

    if (!this.getAuthToken()) {
      return this.refreshPermissions();
    }

    return true;
  }

  canMatchIsEulaAccepted(): boolean | UrlTree {
    return this.eula ? true : this.router.parseUrl('core/eula');
  }

  getPermissions(): Observable<AuthToken> {
    return this.http.get<AuthToken>(`${environment.apiEndpoint}/auth/entrypoint`);
  }

  clearCache(): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/auth/clear-cache`);
  }

  overrideUserEula() {
    const at = this.getAuthToken();
    if (at) {
      const user: AuthToken = { ...at };
      user.user.eula = true;
      sessionStorage.setItem(this.ACCESS_TOKEN_NAME, JSON.stringify(user));
    }
  }

  refreshPermissions(): Observable<boolean | UrlTree> {
    return this.getPermissions().pipe(
      map((userInfo) => this.setAuthToken(userInfo)),
      tap(() => this.setUserId()),
      map(() => true),
      catchError(() => {
        if (this.getAuthToken()) {
          return of(false);
        }
        return of(this.router.parseUrl('login-failed'));
      })
    );
  }

  /**
   * @apiRouteName verify-email 
   * @param data
   * @returns 
   */
  verifyUserEmail(data: UserVerifyEmailRequest): Promise<void> {
    return lastValueFrom(this.http.post<void>(`${environment.apiEndpoint}/verify-email`, data));
  }

}
