import { Connector, ConnectorConfigurationUpdateForm, Connectors } from './../../models/connector.model';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, forkJoin, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { Metrics, HistoricalConnectorPowerTelemetry, ConnectorStatus, ConnectorTelemetry, ConnectorInfo, ConnectorsInfo } from './../../models/metrics.model';
@Injectable({
  providedIn: 'root'
})
export class ConnectorsService {
  baseUrl = `${environment.apiEndpoint}/organizations`
  constructor(private http: HttpClient) { }

  get connectorName() {
    return sessionStorage.getItem('connectorName')
  }

  setConnectorName(name: string) {
    sessionStorage.setItem('connectorName', name);
  }

  getConnector(organizationId: string, siteId: string, connectorId: string): Observable<Connector> {
    return this.http.get<Connector>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}`)
  }

  getConnectorMetrics(organizationId: string, siteId: string, connectorId: string, data: TelemetryQuery): Observable<Metrics> {
    return this.http.post<Metrics>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/metrics`, data)
  }

  getConnectorTelemetry(organizationId: string, siteId: string, connectorId: string): Observable<ConnectorTelemetry> {
    return this.http.get<ConnectorTelemetry>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/telemetry`)
  }

  getConnectorInfo(organizationId: string, siteId: string, connectorId: string): Observable<ConnectorInfo> {
    return this.http.get<ConnectorInfo>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/status`)
  }

  getConnectorStatus(organizationId: string, siteId: string, connectorId: string): Observable<ConnectorStatus> {
    return forkJoin([
      this.getConnectorInfo(organizationId, siteId, connectorId),
      this.getConnector(organizationId, siteId, connectorId)
    ]).pipe(
      map(([ConnectorInfo, Connector]) => {
        const connectorStatus: ConnectorStatus = {
          ...ConnectorInfo,
          ...Connector
        }
        return connectorStatus;
      })
    );
  }

  getHistoricalConnectorStatus(organizationId: string, siteId: string, connectorId: string, data: TelemetryQuery): Observable<ConnectorsInfo> {
    return this.http.post<ConnectorsInfo>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/statuses`, data)
  }

  getConnectorActivePower(organizationId: string, siteId: string, connectorId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/active-power`)
  }

  getHistoricalConnectorPowerTelemetry(organizationId: string, siteId: string, connectorId: string, data: TelemetryQuery): Observable<HistoricalConnectorPowerTelemetry> {
    return this.http.post<HistoricalConnectorPowerTelemetry>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/power-telemetry`, data)
  }

  getHistoricalConnectorTelemetry(organizationId: string, siteId: string, connectorId: string, data: TelemetryQuery): Observable<ConnectorTelemetry> {
    return this.http.post<ConnectorTelemetry>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}/telemetry`, data)
  }

  getStationConnectors(organizationId: string, siteId: string, stationId: string): Observable<Connectors> {
    return this.http.get<Connectors>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/connectors`);
  }

  deleteConnector(organizationId: string, siteId: string, connectorId: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}`)
  }

  updateConnector(organizationId: string, siteId: string, connectorId: string, connector: ConnectorConfigurationUpdateForm): Observable<Connector> {
    return this.http.put<Connector>(`${this.baseUrl}/${organizationId}/sites/${siteId}/connectors/${connectorId}`, connector);
  }

  updateConnectorSwitch(organizationId: string, siteId: string, switchId: string, connectorId: string, connector: Connector): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${organizationId}/sites/${siteId}/switch/${switchId}/connectors/${connectorId}`, connector);
  }

  createConnector(organizationId: string, siteId: string, stationId: string, connector: Connector): Observable<Connector> {
    return this.http.post<Connector>(`${this.baseUrl}/${organizationId}/sites/${siteId}/stations/${stationId}/connectors`, connector);
  }
}
