import { Component } from '@angular/core';
import { AuthService } from '@global/services/auth/auth.service';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss']
})
export class LoginFailedComponent {
  constructor(private authService: AuthService) {}

  clearCache() {
    this.authService.loginFailed();
  }
}
