import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, forkJoin, map, switchMap, tap, throwError } from 'rxjs';
import { Group, GroupUpdateDto } from '@core-shared/models/group.model';
import { PanelList } from '@core-shared/models/panel.model';
import { Schedule } from '@core-shared/models/schedule.model';
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { ActivePower, ConnectorStatuses, Connectivity, HistoricalGroupPowerTelemetries, GroupTelemetry, Metrics, HistoricalGroupConnectivity, ConnectorsInfo } from '@core-shared/models/metrics.model'
import { Connectors } from '@core-shared/models/connector.model';
import { ConnectorsService } from '../connectors/connectors.service';
import { StationResponse } from '@core-shared/models/station.model';


@Injectable({
  providedIn: 'root'
})
export class GroupsService {
  baseUrl = `${environment.apiEndpoint}/organizations`

  constructor(private http: HttpClient,
    private connectorsService: ConnectorsService) { }

  get groupName(): string | null {
    return sessionStorage.getItem('groupName');
  }

  setGroupName(name: string) {
    sessionStorage.setItem('groupName', name);
  }

  createGroup(organizationId: string, group: GroupUpdateDto, siteId: string): Observable<Group> {
    return this.http.post<Group>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups`, group);
  }

  getSiteGroups(organizationId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups`)
  }

  getGroupActivePower(organizationId: string, groupId: string, siteId: string): Observable<ActivePower> {
    return this.http.get<ActivePower>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/active-power`)
  }

  getGroup(organizationId: string, groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}`)
  }

  getGroupSwitches(organizationId: string, groupId: string, siteId: string): Observable<Group> {
    return this.http.get<Group>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/switches`)
  }

  getGroupConnectors(organizationId: string, groupId: string, siteId: string): Observable<Connectors> {
    return this.http.get<Connectors>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/connectors`)
  }

  getGroupConnectorsInfo(organizationId: string, groupId: string, siteId: string): Observable<ConnectorsInfo> {
    return this.http.get<ConnectorsInfo>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/connector-statuses`)
  }

  getGroupConnectorStatuses(organizationId: string, groupId: string, siteId: string): Observable<ConnectorStatuses> {
    return this.getGroupConnectorsInfo(organizationId, groupId, siteId)
      .pipe(
        switchMap((ConnectorsInfo) => {
          const statusesMap = ConnectorsInfo.connectorStatuses.map((info) => ({
            ...info,
            commissioned: false,
            enabled: false
          }));

          const ct: ConnectorStatuses = {
            limit: ConnectorsInfo.limit,
            returnedResults: ConnectorsInfo.returnedResults,
            totalResults: ConnectorsInfo.totalResults,
            offset: ConnectorsInfo.offset,
            connectorStatuses: statusesMap
          };

          // In case there are no connectors associated
          if (ct.totalResults === 0) {
            throw throwError;
          }

          const getConnectorObservables = ct.connectorStatuses.map(connectorStatus =>
            this.connectorsService.getConnector(organizationId, siteId, connectorStatus.connectorId)
          );

          return forkJoin(getConnectorObservables).pipe(
            tap(connectors => {
              connectors.forEach((conn, i) => {
                ct.connectorStatuses[i].commissioned = conn.commissioned;
                ct.connectorStatuses[i].enabled = conn.enabled;
              });
            }),
            map(() => ct)
          );
        }
        ));
  }

  getGroupMetrics(organizationId: string, groupId: string, siteId: string, data: TelemetryQuery): Observable<Metrics> {
    return this.http.post<Metrics>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/metrics`, data)
  }

  updateGroup(organizationId: string, group: GroupUpdateDto, siteId: string): Observable<Group> {
    return this.http.put<Group>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${group.groupId}`, group);
  }

  deleteGroup(organizationId: string, groupId: string, siteId: string): Observable<unknown> {
    return this.http.delete(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}`)
  }

  getPanels(organizationId: string, groupId: string, siteId: string): Observable<PanelList> {
    return this.http.get<PanelList>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/panels`)
  }

  getSchedules(organizationId: string, groupId: string, siteId: string): Observable<Schedule[]> {
    return this.http.get<Schedule[]>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/schedules`);
  }

  getTelemetry(organizationId: string, groupId: string, siteId: string): Observable<GroupTelemetry> {
    return this.http.get<GroupTelemetry>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/telemetry`);
  }

  getGroupTelemetry(organizationId: string, groupId: string, siteId: string, data: TelemetryQuery): Observable<GroupTelemetry> {
    return this.http.post<GroupTelemetry>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/telemetry`, data);
  }

  getHistoricalGroupPowerTelemetry(organizationId: string, groupId: string, siteId: string, data: TelemetryQuery): Observable<HistoricalGroupPowerTelemetries> {
    return this.http.post<HistoricalGroupPowerTelemetries>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/power-telemetry`, data)
  }

  getGroupConnectivity(organizationId: string, groupId: string, siteId: string): Observable<Connectivity> {
    return this.http.get<Connectivity>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/connectivity`);
  }

  getHistoricalGroupConnectivity(organizationId: string, groupId: string, siteId: string, data: TelemetryQuery): Observable<HistoricalGroupConnectivity> {
    return this.http.post<HistoricalGroupConnectivity>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/connectivity`, data);
  }

  getGroupStations(organizationId: string, siteId: string, groupId: string): Observable<StationResponse> {
    return this.http.get<StationResponse>(`${this.baseUrl}/${organizationId}/sites/${siteId}/groups/${groupId}/stations`)
  }
}
