import { Status } from "@core-shared/models/connector.model";
import { ConnectorStatus, ConnectorStatuses } from "@core-shared/models/metrics.model"

export function changeConnectorStatuses(connectorStatuses: ConnectorStatuses): ConnectorStatuses {
  connectorStatuses.connectorStatuses.forEach(x => assignStatus(x))
  return connectorStatuses;
}

export function assignStatus(connectorStatus: ConnectorStatus): ConnectorStatus {
  let status: Status;
  if (connectorStatus.commissioned === false) {
    status = Status.Uncommissioned;
  } else if (connectorStatus.enabled === false) {
    status = Status.Disabled;
  } else if (connectorStatus.status === Status.SuspendedEV) {
    status = Status.Charged;
  } else {
    status = connectorStatus.status as Status ?? Status.Unknown;
  }

  connectorStatus.statusOverride = status;

  return connectorStatus;
}