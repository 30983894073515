import { Component, DestroyRef, Inject, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { RedirectRequest, InteractionStatus } from '@azure/msal-browser';
import { Observable, filter, map } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  private destroyRef = inject(DestroyRef);
  showLoadingIndicator!: Observable<boolean>;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router
  ) { }

  ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length > 0) {
          this.router.navigate(['/core'])
        }
      });

    this.showLoadingIndicator = this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.HandleRedirect
          || (status === InteractionStatus.None && this.authService.instance.getAllAccounts().length > 0)),
        map((status) => {
          if (status === InteractionStatus.HandleRedirect) {
            return false;
          }
          return true;
        }),
        takeUntilDestroyed(this.destroyRef)
      );
  }

  login(userFlowRequest?: RedirectRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

}
