import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { AuthService } from '@global/services/auth/auth.service';
@Directive({
  selector: '[appFeatureControl]'
})
export class FeatureControlDirective {
  private type!: 'disable' | 'remove' | 'block';
  private ref!: EmbeddedViewRef<any>;
  // Permission can be one of the following options  'Deactivated' | 'Driver' | 'Installer' | 'Read_Access' | 'Site_Admin' | 'Org_Admin' | 'AP_Superuser'
  private permission!: number[];
  private id!: string;
  private userPermissions!: number[] | undefined;
  @Input()
  set appFeatureControl(value: any) {
    this.permission = value;
    this.process();
  }

  @Input()
  set appFeatureControlType(value: 'disable' | 'remove' | 'block') {
    this.type = value;
    this.process();
  }

  @Input()
  set appFeatureControlId(value: string) {
    this.id = value;
    this.process();
  }
  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private element: ElementRef,
    private renderer: Renderer2,
    private authService: AuthService) {
  }

  process() {
    if (!this.type || !this.permission || !this.id) {
      this.show();
      return;
    }

    const orgMap = this.authService.orgMap;
    const siteMap = this.authService.siteMap;

    Object.keys(orgMap).includes(this.id) ? this.userPermissions = Object.getOwnPropertyDescriptor(orgMap, this.id)?.value
      : Object.keys(siteMap).includes(this.id) ? this.userPermissions = Object.getOwnPropertyDescriptor(siteMap, this.id)?.value
        : this.userPermissions = undefined;
    // if user is a super user, dont block anything
    if (this.authService.superUser) {
      return;
    } else if (this.permission.some(item => this.userPermissions?.includes(item))) { // if user has the required permission, show the component
      return;
    } else { //if user is not a super user and also does not have permissions then perform the required functions
      switch (this.type) {
        case 'remove':
          this.remove();
          break;
        case 'disable':
          this.disable();
          break;
        case 'block':
          this.block();
          break;
      }
    }
  }

  disable() {
    this.element.nativeElement.previousElementSibling.disabled = true;
    this.renderer.addClass(this.element.nativeElement.previousElementSibling, 'overlay');
    this.renderer.setAttribute(this.element.nativeElement.previousElementSibling, 'inert', 'true');
  }

  remove() {
    this.viewContainer.clear();
  }

  block() {
    this.renderer.addClass(this.element.nativeElement.previousElementSibling, 'block-ui');
    this.renderer.setAttribute(this.element.nativeElement.previousElementSibling, 'inert', 'true');
  }

  show() {
    if (this.ref) {
      return;
    }
    this.ref = this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
