import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit, OnDestroy {
  @Input() control!: UntypedFormControl | AbstractControl | AbstractControl<any, any> | null;
  @Input() label!: string;
  @Input() required!: boolean;
  @Output() valueChanged = new EventEmitter();
  private subscription!: Subscription;

  constructor() {
  }

  ngOnInit(): void {
    if (this.control) {
      this.subscription = this.control.valueChanges.subscribe((val) => {
        this.valueChanged.emit(val);
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
