import { Component, OnInit } from '@angular/core';
import { AuthService } from '@global/services/auth/auth.service';
import { UserInitialSetupService } from '../../services/user-initial-setup/user-initial-setup.service';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-eula',
  templateUrl: './eula.component.html',
  styleUrls: ['./eula.component.scss'],
})
export class EulaComponent implements OnInit {
  eulaContent!: Observable<string>;
  eulaIsAccepted!: boolean;
  fromProfile: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private setupService: UserInitialSetupService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.eulaIsAccepted = !this.authService.eula;
    this.eulaContent = this.setupService.getEula();
    this.route.paramMap.subscribe((data) => {
      data.has('isProfile') ? this.fromProfile = true : this.fromProfile = false;
    });
  }

  accept() {
    const organizationId = this.authService.getAuthToken()?.user.organizationId ?? "";
    this.setupService.acceptEula(organizationId, { id: "3", eula: true }).pipe(
      switchMap(() => this.authService.getPermissions().pipe(catchError(_ => {
        this.authService.overrideUserEula();
        return of(this.authService.getAuthToken());
      }))),
      map((authToken) => {
        if (authToken) {
          this.authService.setAuthToken(authToken);
        }
      }),
    ).subscribe(() => {
      this.authService.overrideUserEula();
      this.fromProfile ? this.router.navigate(['/core/user/profile']) : this.router.navigate(['/core']);
    });
  }

  reject() {
    this.authService.logout();
  }

}
