import { EulaComponent } from '../../components/eula/eula.component';
import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '@global/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EulaService {
  eulaRef!: DynamicDialogRef;
  modalStatus = false;
  constructor(private authService: AuthService,
    private dialog: DialogService) { }

  get modalOpen() {
    return this.modalStatus;
  }

  accept() {
    // const data = {
    //   id: this.authService.user_id,
    //   eula: true
    // }
    // this.setupService.acceptEula(data).then(async () => {
    //   await this.authService.refreshPermissions();
    //   const orgMap = Object.keys(this.authService.orgMap);
    //   const siteMap = Object.keys(this.authService.siteMap);

    //   const firstOrg = orgMap.shift();
    //   const firstSite = siteMap.shift();

    //   if (this.authService.superUser) {
    //     this.authService.navToOrganizationList();
    //   } else if (orgMap.length > 1) { // nav to org list component if more than 1 organization exists
    //     this.authService.navToOrganizationList();
    //   } else if (siteMap.length > 1 && firstOrg) { // nav to site list component if more than 1 site exists
    //     this.authService.navToSiteList(firstOrg);
    //   } else if ((firstOrg && orgMap.length === 1) && (firstSite && siteMap.length === 1)) { // if not nav to connectors dashboard
    //     this.authService.navToSiteDashboard(firstOrg, firstSite)
    //   } else {
    //     this.authService.navToOrganizationList();
    //   }
    // }
    // ).catch(() => {
    //   this.notify.showError("Error accepting EULA")
    // })
  }

  reject() {
    this.authService.logout();
  }

  showEULA() {
    if (!this.modalStatus) {
      this.modalStatus = true;
      this.eulaRef = this.dialog.open(EulaComponent, {
        header: 'Atom Cloud End User License Agreement (EULA)',
        closable: false,
        closeOnEscape: false,
        modal: true,
        width: '70%'
      });
      this.eulaRef.onClose.subscribe(async (accepted) => {
        this.modalStatus = false;
        if (accepted) {
          await this.accept();
        } else {
          await this.reject();
        }
      });
    }
  }

  showEULAonProfile() {
    this.modalStatus = true;
    this.eulaRef = this.dialog.open(EulaComponent, {
      header: 'Atom Cloud End User License Agreement (EULA)',
      closable: true,
      closeOnEscape: true,
      modal: true,
      width: '70%'
    });
  }
}
