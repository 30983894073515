import { StateService } from '@core-shared/services/state/state.service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@global/services/auth/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  static readonly ROUTE_DATA_SIDEMENU = 'sideMenu';
  public organizationId!: string;
  public siteId!: string;
  public panelId!: string;
  public groupId!: string;
  public stationId!: string;
  public connectorId!: string;
  menuItems!: MenuItem[];
  constructor(private stateService: StateService, private auth: AuthService) {
    this.stateService.organizationId.subscribe((id) => { this.organizationId = id; })
    this.stateService.siteId.subscribe((id) => { this.siteId = id; })
    this.stateService.panelId.subscribe((id) => { this.panelId = id; })
    this.stateService.groupId.subscribe((id) => { this.groupId = id; })
    this.stateService.connectorId.subscribe((id) => { this.connectorId = id; })
    this.stateService.stationId.subscribe((id) => { this.stationId = id })
    this.stateService.sideMenu.subscribe((menuItem) => {
      this.menuItems = menuItem;
      this.addUrls();
    })
  }

  ngOnInit(): void {
  }

  addUrls() {
    this.menuItems.forEach((entry) => {
    entry.title === 'Organizations' ? entry.url = 'organization/' :
    entry.title === 'Site Map' ? entry.url = 'site-map/' :
    entry.title === 'Manufacturing' && this.auth.superUser ? (entry.url = 'manufacturing/') && (entry.disabled = false) :
    entry.title === 'Manufacturing' && !this.auth.superUser ? (entry.url = 'manufacturing/') && (entry.disabled = true) :
    entry.title === 'Change Password' ? entry.url = 'user/change-password' :
    entry.title === 'Profile' ? entry.url = 'user/profile' :
    entry.title === 'Configuration' ? entry.url = 'organization/' + this.organizationId + '/configuration' :
    entry.title === 'Connectors' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/connectors' :
    entry.title === 'Site Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/site-configuration' :
    entry.title === 'Feeder Groups' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders' :
    entry.title === 'Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/dashboard' :
    entry.title === 'Panel Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/panel-dashboard' :
    entry.title === 'Panel Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/panel-configuration' :
    entry.title === 'Panel Connectors' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/panel-connectors' :
    entry.title === 'Panel Stations' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/stations' :
    entry.title === 'Panels' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels' :
    entry.title === 'Update Panel Current' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/current-limit' :
    entry.title === 'Rebate Reports' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/rebate-reports' :
    entry.title === 'Sites' ? entry.url = 'organization/' + this.organizationId + '/sites/' :
    entry.title === 'Group Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/group-dashboard' :
    entry.title === 'Group Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/group-configuration' :
    entry.title === 'Group Panels' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/group-panels' :
    entry.title === 'Energy Schedules' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/energy-schedules' :
    entry.title === 'Energy Defaults' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/feeders/' + this.groupId + '/energy-defaults' :
    entry.title === 'Connector Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/connectors/' + this.connectorId + '/connector-dashboard' :
    entry.title === 'Connector Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/connectors/' + this.connectorId + '/connector-configuration' :
    entry.title === 'Integrations' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/connectors/' + this.connectorId + '/connector-integrations' :
    entry.title === 'Users' ? entry.url = 'organization/' + this.organizationId + '/users' :
    entry.title === 'Station Configuration' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/stations/' + this.stationId + '/station-configuration' : 
    entry.title === 'Station Dashboard' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/stations/' + this.stationId + '/station-dashboard' : 
    entry.title === 'Station Connectors' ? entry.url = 'organization/' + this.organizationId + '/sites/' + this.siteId + '/panels/' + this.panelId + '/stations/' + this.stationId + '/station-connectors' : 
    entry.title === 'Org Configuration' ? entry.url = 'organization/' + this.organizationId + '/configuration' : entry.url = '' ;
    })
  }
}
