import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@global/services/auth/auth.service';
import { UserVerifyEmailRequest } from '@global/services/auth/models/verify-email.model';
import { NotificationService } from '@global/services/notification/notification.service';
import { Observable, map, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {
  readonly QUERY_PARAM_ID = 'id'
  readonly QUERY_PARAM_CODE = 'code'
  readonly QUERY_PARAM_ORGANIZATION_ID = 'organization_id'
  userLoginForm!: FormGroup;
  showForm = false;
  showPassword = false;

  isSending = false;
  isVerified = false;
  isRedirecting = false;

  redirect = 5000;
  countdown = 5;
  redirectTimer: Observable<number> | null = null;

  validatePasswordRequirements(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pass = control.value;
      return this.isValidPassword(pass) ? null : { passwordRequirements: true };
    }
  }

  checkPasswords(group: AbstractControl) {
    let pass = group.get('password')?.value;
    let confirmPass = group.get('confirmPassword')?.value
    return pass === confirmPass ? null : { notSame: true }
  }

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private auth: AuthService,
    private notify: NotificationService,
    private router: Router,
  ) { }

  ngOnInit() {
    const passwords = this.fb.group({
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.validatePasswordRequirements()
      ]],
      confirmPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(64),
        this.validatePasswordRequirements()
      ]]
    }, {
      validators: this.checkPasswords
    })

    this.userLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      passwords: passwords
    });

    const verifyEmailId = this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_ID);
    const verifyEmailCode = this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_CODE);
    const verifyOrganizationId = this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_ORGANIZATION_ID);

    // Handle arriving at this page without query parameters present
    if (verifyEmailCode && verifyEmailId && verifyOrganizationId && verifyOrganizationId) {
      this.showForm = true;
    }
  }

  onSubmit = () => {
    const verifyEmailId = this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_ID);
    const verifyEmailCode = this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_CODE);
    const verifyOrganizationId = this.route.snapshot.queryParamMap.get(this.QUERY_PARAM_ORGANIZATION_ID);

    if (!verifyEmailCode || !verifyEmailId || !verifyOrganizationId) {
      this.notify.showError(`Missing code, id, or organization id`);
      return new Promise<any>((_resolve, reject) => reject());
    }

    const request: UserVerifyEmailRequest = {
      id: verifyEmailId,
      secretCode: verifyEmailCode,
      organizationId: verifyOrganizationId,
      email: this.userLoginForm.value['email'],
      password: this.userLoginForm.value['passwords'].password
    }

    this.isSending = true;
    this.showForm = false;

    return this.auth.verifyUserEmail(request).then(() => {
      this.notify.showSuccess(`Success`);
      this.isSending = false;
      this.isRedirecting = true;
      timer(0, 1000).pipe(
        takeUntil(timer(this.redirect + 1000)),
        map((x) => {
          this.countdown = (this.redirect / 1000) - x;
          return this.countdown;
        })
      ).subscribe({
        complete: async () => {
          await this.router.navigateByUrl("/");
        }
      })
    }).catch(() => {
      this.showForm = true;
      this.notify.showError(`Error: Invalid id, email, code, or code is expired`);
    }).finally(() => {
      this.isSending = false;
    })
  }

  isValidPassword(password: string): boolean {
    if (password.length < 8 || password.length > 64) {
      return false;
    }

    let hasUpper = false;
    let hasLower = false;
    let hasNumber = false;
    let hasSymbol = false;

    for (const char of password) {
      if (char.toUpperCase() !== char) {
        hasLower = true;
      } else if (char.toLowerCase() !== char) {
        hasUpper = true;
      } else if (!isNaN(parseInt(char))) {
        hasNumber = true;
      } else {
        hasSymbol = true;
      }
    }

    return hasUpper && hasLower && hasNumber && hasSymbol;
  }

}
