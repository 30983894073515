import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  }
  
  @Injectable()
  export class CanDeactivateGuard  {
    canDeactivate(component: CanComponentDeactivate, 
             _route: ActivatedRouteSnapshot, 
             _state: RouterStateSnapshot) {
       return component.canDeactivate ? component.canDeactivate() : true;
    }
  } 