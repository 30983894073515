<div id="top-nav" class="top-nav">
  <img src="assets/images/logo.svg" alt="Epurpl logo">
  <p class="site-manager"></p>
</div>

<section>
  <p-card header="Atom Cloud" subheader="End User License Agreement">
    <ng-template pTemplate="content">
      <ng-container *ngIf="(eulaContent | async) as content; else isLoading">
        <textarea readonly [value]="content" [disabled]="true" pInputTextarea rows="30" cols="60"
          style="border-bottom: 0;"></textarea>
      </ng-container>
      <ng-template #isLoading>
        <p-progressSpinner></p-progressSpinner>
      </ng-template>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button label="Accept" (click)="accept()" icon="pi pi-check"></p-button>
      <p-button label="Reject" (click)="reject()" icon="pi pi-times" styleClass="p-button-secondary"
        [style]="{ 'margin-left': '.5em' }"></p-button>
    </ng-template>
  </p-card>

</section>

<app-footer></app-footer>