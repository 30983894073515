import { ConnectorsService } from '@core-shared/services/connectors/connectors.service';
import { GroupsService } from '@core-shared/services/groups/groups.service';
import { PanelsService } from '@core-shared/services/panels/panels.service';
import { BreadcrumbService } from '@core-shared/services/breadcrumb/breadcrumb.service';
import { SitesService } from '@core-shared/services/sites/sites.service';
import { OrganizationsService } from '@core-shared/services/organizations/organizations.service';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router, ActivatedRoute } from '@angular/router';
import { StationService } from '@core-shared/services/station/station.service';
@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  static readonly ICON_DATA_BREADCRUMB = 'icon';
  static readonly SIDEMENU_DATA = 'sideMenu';
  private readonly crumbLabelsOrganizations: String[] = ['Sites', 'Org Configuration'];
  private readonly crumbLabelsSites: String[] = ['Connectors', 'Feeder Groups', 'Rebate Reports', 'Panels', 'Dashboard', 'Site Configuration'];
  private readonly crumbLabelsPanels: String[] = ['Panel Dashboard', 'Panel Configuration', 'Panel Connectors', 'Panel Stations', 'Update Panel Current'];
  private readonly crumbLabelsGroups: String[] = ['Group Dashboard', 'Group Configuration', 'Group Panels', 'Energy Defaults', 'Energy Schedules'];
  private readonly crumLabelStations: String[] = ['Station Dashboard', 'Station Configuration', 'Station Connectors']
  private readonly crumblLabelsConnectors: String[] = ['Connector Dashboard', 'Connector Configuration', 'OCPP', 'Integration']

  menuItems!: MenuItem[];
  constructor(private activatedRoute: ActivatedRoute, private orgService: OrganizationsService, private panelService: PanelsService,
              private siteService: SitesService, private groupService: GroupsService, private connectorService: ConnectorsService,
              private router: Router, private breadcrumbService: BreadcrumbService, private stationService: StationService) {
                this.router.events.subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
                this.breadcrumbService.getEvent().subscribe(() => this.menuItems = this.createBreadcrumbs(this.activatedRoute.root))
              }

  ngOnInit(): void {
    this.menuItems = this.createBreadcrumbs(this.activatedRoute.root)
  }

  private createBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      const routerLink = [url];
      let crumb = child.snapshot.data[BreadcrumbsComponent.ROUTE_DATA_BREADCRUMB];
      // Do not delete this line -> can be used to add icons if required later
      // const icon = child.snapshot.data[BreadcrumbsComponent.ICON_DATA_BREADCRUMB];
      const disabled = true;
      const target = undefined
      if (crumb != null) {
        let label = crumb.value;
        breadcrumbs.push({ label, target, routerLink });
        if (this.crumbLabelsOrganizations.includes(crumb.label)) {
          label = this.orgService.organizationName;
          label = !label?.trim() ? 'N/A' : label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, { label, target, disabled, routerLink });
          }
        } else if (this.crumbLabelsSites.includes(crumb.label)) {
          label = this.siteService.siteName;
          label = !label?.trim() ? 'N/A' : label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, { label, target, disabled, routerLink });
          }
        } else if (this.crumbLabelsPanels.includes(crumb.label)) {
          label = this.panelService.panelName;
          label = !label?.trim() ? 'N/A' : label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, { label, target, disabled, routerLink });
          }
        } else if (this.crumbLabelsGroups.includes(crumb.label)) {
          label = this.groupService.groupName;
          label = !label?.trim() ? 'N/A' : label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, { label, target, disabled, routerLink });
          }
        } else if (this.crumblLabelsConnectors.includes(crumb.label)) {
          label = this.connectorService.connectorName;
          label = !label?.trim() ? 'N/A' : label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0, { label, target, disabled, routerLink });
          }
        } else if (this.crumLabelStations.includes(crumb.label)) {
          label = this.stationService.stationName;
          label = !label?.trim() ? 'N/A' : label
          if (label != null) {
            breadcrumbs.splice(breadcrumbs.length - 1, 0)
          }
        }

      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

}
