import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { eula } from '../../models/user-initial-setup.model';
import { User } from '../../models/user.model'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInitialSetupService {
  baseUrlEula = `${environment.apiEndpoint}/users`
  baseUrl = `${environment.apiEndpoint}/organizations`
  
  constructor(private http: HttpClient) { }

  getEula(): Observable<any> {
    return this.http.get<any>(`${this.baseUrlEula}/eula`);
  }

  acceptEula(organizationId: string, data: eula): Observable<any> {
    return this.http.post(`${this.baseUrl}/${organizationId}/users/eula`, data);
  }

  /**
   * Gets the logged in user's profile information
   * @returns user model of the logged in user.
   */
  getUserProfile(organizationId: string): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/${organizationId}/users/profile`);
  }
}
