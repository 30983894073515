import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { forkJoin, map, Observable, switchMap, tap, throwError } from 'rxjs';
import { Panel, PanelAssignmentReturn, PanelTelemetry, UpdatePanelCurrentLimitDto } from '@core-shared/models/panel.model';
import { Switch } from '@core-shared/models/switch.model';
import { ConnectorsInfo, ConnectorStatuses, HistoricalPanelPowerTelemetries, Metrics } from '@core-shared/models/metrics.model'
import { TelemetryQuery } from '@core-shared/models/telemetry-query.model';
import { changeConnectorStatuses } from '@core-shared/components/connector-status-modifier/connector-status-modifier';
import { StationResponse } from '@core-shared/models/station.model';
import { ConnectorsService } from '../connectors/connectors.service';

@Injectable({
  providedIn: 'root'
})
export class PanelsService {
  baseUrl = `${environment.apiEndpoint}/organizations`

  constructor(private http: HttpClient,
    private connectorsService: ConnectorsService) { }

  get panelName(): string | null {
    return sessionStorage.getItem('panelName');
  }

  setPanelName(name: string) {
    sessionStorage.setItem('panelName', name);
  }

  getPanel(organizationId: string, panelId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}`)
  }

  getPanelMetrics(organizationId: string, panelId: string, siteId: string, data: TelemetryQuery): Observable<Metrics> {
    return this.http.post<Metrics>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/metrics`, data)
  }

  getPanelConnectorsInfo(organizationId: string, panelId: string, siteId: string): Observable<ConnectorsInfo> {
    return this.http.get<ConnectorsInfo>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/connector-statuses`)
  }

  getPanelConnectorStatuses(organizationId: string, panelId: string, siteId: string): Observable<ConnectorStatuses> {
    return this.getPanelConnectorsInfo(organizationId, panelId, siteId)
      .pipe(
        switchMap((ConnectorsInfo) => {
          const statusesMap = ConnectorsInfo.connectorStatuses.map((info) => ({
            ...info,
            commissioned: false,
            enabled: false
          }));

          const ct: ConnectorStatuses = {
            limit: ConnectorsInfo.limit,
            returnedResults: ConnectorsInfo.returnedResults,
            totalResults: ConnectorsInfo.totalResults,
            offset: ConnectorsInfo.offset,
            connectorStatuses: statusesMap
          };

          // In case there are no connectors associated
          if (ct.totalResults === 0) {
            throw throwError;
          }

          const getConnectorObservables = ct.connectorStatuses.map(connectorStatus =>
            this.connectorsService.getConnector(organizationId, siteId, connectorStatus.connectorId)
          );

          return forkJoin(getConnectorObservables).pipe(
            tap(connectors => {
              connectors.forEach((conn, i) => {
                ct.connectorStatuses[i].commissioned = conn.commissioned;
                ct.connectorStatuses[i].enabled = conn.enabled;
              });
            }),
            map(() => ct)
          );
        })
      );
  }

  getPanelConnectorStatusesWithStatusOverride(organizationId: string, panelId: string, siteId: string): Observable<ConnectorStatuses> {
    return this.http.get<ConnectorStatuses>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/connector-statuses`)
      .pipe(
        map((x) => changeConnectorStatuses(x))
      );
  }

  getPanelBySerial(organizationId: string, serialId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/serial/${serialId}`)
  }

  getZonePanels(organizationId: string, zoneId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/zones/${zoneId}/panels`)
  }

  getPanelOverview(organizationId: string, panelId: string, siteId: string): Observable<Panel> {
    return this.http.get<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/overview`)
  }

  addPanelToGroup(organizationId: string, panelId: string, siteId: string, groupId: string, data: any): Observable<PanelAssignmentReturn> {
    return this.http.post<PanelAssignmentReturn>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/groups/${groupId}`, data)
  }

  removePanelFromGroup(organizationId: string, panelId: string, siteId: string, groupId: string): Observable<PanelAssignmentReturn> {
    return this.http.delete<PanelAssignmentReturn>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/groups/${groupId}`)
  }

  updatePanelZone(organizationId: string, panelId: string, siteId: string, zoneId: string, data: any): Observable<Panel> {
    return this.http.put<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/zones/${zoneId}`, data)
  }

  removePanelFromZone(organizationId: string, panelId: string, siteId: string, zoneId: string): Observable<Panel> {
    return this.http.delete<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/zones/${zoneId}`)
  }

  assignPanelToGroup(organizationId: string, panelId: string, siteId: string, data: any): Observable<PanelAssignmentReturn> {
    return this.http.post<PanelAssignmentReturn>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/assign-panel`, data)
  }

  updatePanel(organizationId: string, panel: Panel, siteId: string): Observable<null> {
    return this.http.put<null>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panel.panelId}`, panel);
  }

  updatePanelGroup(organizationId: string, panel: Panel, siteId: string, groupId: string): Observable<Panel> {
    return this.http.put<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panel.panelId}/groups/${groupId}`, {});
  }

  deletePanel(organizationId: string, panelId: string, siteId: string): Observable<Panel> {
    return this.http.delete<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}`)
  }

  getSwitches(organizationId: string, panelId: string, siteId: string): Observable<Switch[]> {
    return this.http.get<Switch[]>(`${this.baseUrl}/${organizationId}/sites/${organizationId}/sites/${siteId}/panels/${panelId}/switches`);
  }

  getPanelActivePower(organizationId: string, panelId: string, siteId: string): Observable<number> {
    return this.http.get<number>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/active-power`)
  }

  getPanelTelemetry(organizationId: string, panelId: string, siteId: string, data: TelemetryQuery): Observable<PanelTelemetry> {
    return this.http.post<PanelTelemetry>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/telemetry`, data);
  }

  getHistoricalPanelPowerTelemetry(organizationId: string, panelId: string, siteId: string, data: TelemetryQuery): Observable<HistoricalPanelPowerTelemetries> {
    return this.http.post<HistoricalPanelPowerTelemetries>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/power-telemetry`, data);
  }

  getPanelStations(organizationId: string, panelId: string, siteId: string): Observable<StationResponse> {
    return this.http.get<StationResponse>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/stations`)
  }

  updatePanelCurrentLimit(organizationId: string, panelId: string, siteId: string, data: UpdatePanelCurrentLimitDto): Observable<Panel> {
    return this.http.put<Panel>(`${this.baseUrl}/${organizationId}/sites/${siteId}/panels/${panelId}/current-limit`, data)
  }
}
