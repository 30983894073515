import { Injectable } from '@angular/core';
import { catchError, Observable, of, ReplaySubject } from 'rxjs';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { SitesService } from '../sites/sites.service';
interface Params {
  organizationId: string;
  siteId: string;
  groupId: string;
  panelId: string;
  connectorId: string;
  stationId: string;
}
@Injectable({
  providedIn: 'root'
})
export class StateService  {
  readonly DEFAULT_TIME_ZONE = 'UTC';

  private organizationId$ = new ReplaySubject<string>(1);
  private siteId$ = new ReplaySubject<string>(1);
  private groupId$ = new ReplaySubject<string>(1);
  private panelId$ = new ReplaySubject<string>(1);
  private connectorId$ = new ReplaySubject<string>(1);
  private sideMenu$ = new ReplaySubject<any[]>(1);
  private stationId$ = new ReplaySubject<string>(1);
  private params!: Params;

  static readonly ROUTE_DATA_SIDEMENU = 'sideMenu';
  constructor(
    private sitesService: SitesService,
  ) { }

  get organizationId() {
    return this.organizationId$.asObservable();
  }

  get siteId() {
    return this.siteId$.asObservable();
  }

  get groupId() {
    return this.groupId$.asObservable();
  }

  get panelId() {
    return this.panelId$.asObservable();
  }

  get connectorId() {
    return this.connectorId$.asObservable();
  }

  get sideMenu() {
    return this.sideMenu$.asObservable();
  }
  get siteTimezone() {
    return sessionStorage.getItem("timeZone") ?? this.DEFAULT_TIME_ZONE;
  }

  get stationId() {
    return this.stationId$.asObservable();
  }

  setSiteTimezone(timeZone = this.DEFAULT_TIME_ZONE) {
    sessionStorage.setItem('timeZone', timeZone)
  }

  /**
   * Listens for changes to siteId. If siteId changes then a call is made to get the timezone
   * for the site. Any errors will result in 'UTC' being returned.
  */
  public get siteTimeZone$() {
    let orgId: any
    this.organizationId.subscribe((id) => orgId = id)
    let siteId: any
    this.siteId.subscribe((id) => siteId = id)
    
    return  this.sitesService.getSiteTimezone(orgId, siteId).pipe(
            catchError(() => of(this.DEFAULT_TIME_ZONE)),
          )
  }

  canActivateChild(r: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const data = r.data;
    this.params = r.params as Params;
    if (this.params.organizationId) {
      this.organizationId$.next(this.params.organizationId);
    }
    if (this.params.siteId) {
      this.siteId$.next(this.params.siteId);
    }
    if (this.params.groupId) {
      this.groupId$.next(this.params.groupId);
    }
    if (this.params.panelId) {
      this.panelId$.next(this.params.panelId);
    }
    if (this.params.connectorId) {
      this.connectorId$.next(this.params.connectorId);
    }
    if (this.params.stationId) {
      this.stationId$.next(this.params.stationId);
    }
    if (data[StateService.ROUTE_DATA_SIDEMENU]) {
      this.sideMenu$.next(data[StateService.ROUTE_DATA_SIDEMENU])
    }
    return true;
  }
}
